<template>
  <div>
    <a-table bordered :data-source="list" :pagination="false">
      <a-table-column
        title="关系"
        data-index="relation"
        align="center"
      ></a-table-column>
      <a-table-column
        title="姓名"
        data-index="name"
        align="center"
      ></a-table-column>

      <a-table-column title="年龄" data-index="age" align="center" />
      <a-table-column title="工作单位" data-index="company" />
      <a-table-column title="职务" data-index="post" />
      <a-table-column title="操作" align="center">
        <template slot-scope="text, record, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>

    <div class="center">
      <a-button @click="$close($route.path)">关闭</a-button>
    </div>

    <a-modal
      :visible="familyVisible"
      title="添加家庭成员"
      :footer="null"
      @cancel="cancel"
    >
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-form-item label="关系">
          <a-input
            v-decorator="[
              'relation',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item label="姓名">
          <a-input
            v-decorator="[
              'name',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item label="年龄">
          <a-input-number
            :min="0"
            :max="100"
            v-decorator="['age']"
            style="width: 100%"
          />
        </a-form-item>

        <a-form-item label="工作单位">
          <a-input v-decorator="['company']" />
        </a-form-item>

        <a-form-item label="职务">
          <a-input v-decorator="['post']" />
        </a-form-item>
        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">确认</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { edit } from "@/api/recruit";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      form: this.$form.createForm(this),
    };
  },

  computed: {
    ...mapState("interviewDetail", ["detail", "familyVisible"]),
    list() {
      return Array.isArray(this.detail.hisFamilyList)
        ? this.detail.hisFamilyList
        : [];
    },
  },

  methods: {
    ...mapMutations("interviewDetail", ["setFamilyVisible"]),
    ...mapActions("interviewDetail", ["getDetail"]),

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);
          edit({
            ...this.detail,
            hisFamilyList: [...this.list, values],
          }).then(() => {
            this.getDetail();
            this.cancel();
          });
        }
      });
    },

    cancel() {
      this.setFamilyVisible(false);
      this.form.resetFields();
    },

    remove(index) {
      this.list.splice(index, 1);
      edit({
        ...this.detail,
        hisFamilyList: this.list,
      }).then(() => {
        this.getDetail();
      });
    },
  },
};
</script>


<style lang="less" scoped>
.center {
  margin-top: 80px;
}
</style>