var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"pagination":false}},[_c('a-table-column',{attrs:{"title":"校内职务","data-index":"position","align":"center"}}),_c('a-table-column',{attrs:{"title":"起止时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_vm._v(" "+_vm._s(text.timeFrom)+"-"+_vm._s(text.timeTo ? text.timeTo : "至今")+" ")]}}])}),_c('a-table-column',{attrs:{"title":"负责具体内容","data-index":"jobContent","align":"center"}}),_c('a-table-column',{attrs:{"title":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text, record, index){return [_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remove(index)}}},[_vm._v("删除")])]}}])})],1),_c('div',{staticClass:"center"},[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1),_c('a-modal',{attrs:{"visible":_vm.campVisible,"title":"添加实习经历","footer":null},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 16 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"校内职务"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'position',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'position',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"起止时间"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'time',
            { rules: [{ required: true, message: '请选择！' }] },
          ]),expression:"[\n            'time',\n            { rules: [{ required: true, message: '请选择！' }] },\n          ]"}],staticStyle:{"width":"100%"}})],1),_c('a-form-item',{attrs:{"label":"负责具体内容"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'jobContent',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'jobContent',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("确认")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }