<template>
  <div>
    <a-descriptions
      bordered
      :column="2"
      v-for="(item, index) in workList"
      :key="item.id"
      style="margin-bottom: 24px"
    >
      <a-descriptions-item>
        <div slot="label" class="center">单位名称</div>
        <div>
          <span>
            {{ item.company }}
          </span>
          <a href="#" class="danger" @click="remove(index)">
            <a-icon type="delete" />
          </a>
        </div>
      </a-descriptions-item>

      <a-descriptions-item>
        <div slot="label" class="center">起止时间</div>
        <div>{{ item.timeFrom }}-{{ item.timeTo ? item.timeTo : "至今" }}</div>
      </a-descriptions-item>

      <a-descriptions-item>
        <div slot="label" class="center">职务</div>
        <div>{{ item.position }}</div>
      </a-descriptions-item>

      <a-descriptions-item>
        <div slot="label" class="center">薪资待遇/年薪</div>
        <div>{{ item.salary }}万/年</div>
      </a-descriptions-item>

      <a-descriptions-item :span="2">
        <div slot="label" class="center">工作内容</div>
        <div>{{ item.jobContent }}</div>
      </a-descriptions-item>

      <a-descriptions-item :span="2">
        <div slot="label" class="center">离职原因</div>
        <div>
          {{ item.leavingReason }}
        </div>
      </a-descriptions-item>

      <a-descriptions-item :span="2">
        <div slot="label" class="center">直接上级</div>
        <div>
          {{ item.superior }} {{ item.superiorPosition }}
          {{ item.superiorMobile }}
        </div>
      </a-descriptions-item>
    </a-descriptions>

    <a-modal
      :visible="workVisible"
      title="添加工作经历"
      :footer="null"
      @cancel="cancel"
      width="800px"
    >
      <a-form
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col :span="12">
            <a-form-item label="单位名称">
              <a-input
                v-decorator="[
                  'company',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="起止时间">
              <a-range-picker
                v-decorator="[
                  'time',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="职务">
              <a-input
                v-decorator="[
                  'position',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="年薪(万/年)">
              <a-input-number
                :min="1"
                v-decorator="[
                  'salary',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              label="工作内容"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 20 }"
            >
              <a-textarea
                v-decorator="[
                  'jobContent',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              label="离职原因"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 20 }"
            >
              <a-textarea
                v-decorator="[
                  'leavingReason',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="直接上级姓名">
              <a-input
                v-decorator="[
                  'superior',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="直接上级职务">
              <a-input
                v-decorator="[
                  'superiorPosition',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="联系方式">
              <a-input
                v-decorator="[
                  'superiorMobile',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">确认</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>

    <div class="center" style="margin-top: 80px">
      <a-button @click="$close($route.path)">关闭</a-button>
    </div>
  </div>
</template>

<script>
import { edit } from "@/api/recruit";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      form: this.$form.createForm(this),
    };
  },

  computed: {
    ...mapState("interviewDetail", ["detail", "workVisible"]),
    ...mapGetters("interviewDetail", ["workList"]),
  },

  methods: {
    ...mapMutations("interviewDetail", ["setWorkVisible"]),
    ...mapActions("interviewDetail", ["getDetail"]),

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);

          let timeFrom, timeTo;
          if (Array.isArray(values.time) && values.time.length === 2) {
            timeFrom = values.time[0].format("YYYY-MM-DD");
            timeTo = values.time[1].format("YYYY-MM-DD");
          }

          edit({
            ...this.detail,
            hisWorkList: [
              ...this.workList,
              {
                ...values,
                timeFrom,
                timeTo,
                time: undefined,
              },
            ],
          }).then(() => {
            this.getDetail();
            this.cancel();
          });
        }
      });
    },

    cancel() {
      this.setWorkVisible(false);
      this.form.resetFields();
    },

    remove(index) {
      const newList = [...this.workList];
      newList.splice(index, 1);
      edit({
        ...this.detail,
        hisWorkList: newList,
      }).then(() => {
        this.getDetail();
      });
    },
  },
};
</script>