<template>
  <div class="list">
    <div class="header">面试评价</div>
    <template v-if="remarksList.length > 0">
      <div class="item" v-for="(item, index) in remarksList" :key="index">
        <div class="left" style="padding: 10px 0">
          <span style="font-weight: bold" class="title">{{ item.title }}</span>

          <span style="margin-left: auto">面试官：{{
            item.personal
            ? item.personal.userName + "," + item.personal.juryUserNames
            : ""
          }}</span>
          <span style="margin-left: 8px">面试时间：{{ item.personal ? item.personal.createAt : "" }}</span>
        </div>
        <div class="evaluation">
          <div class="evaluation-card">
            <div class="evaluation-header">
              <div class="title">面试评分</div>
              <div class="title" v-if="Array.isArray(item.subjectList)">
                {{
                  getAllScore(
                    Array.isArray(item.personal.optionList)
                      ? item.personal.optionList
                      : [],
                    item.subjectList.filter(
                      (subject) => subject.subjectGroup === "面试评分"
                    )
                  )
                }}分
              </div>
            </div>
            <div>
              <div v-if="Array.isArray(item.subjectList)">
                <div v-for="element in item.subjectList.filter(
                      (subject) => subject.subjectGroup === '面试评分'
                    )" :key="element.id" class="between subject">
                  <div>{{ element.title }}</div>
                  <div class="title">
                    {{
                      getScore(
                        Array.isArray(item.personal.optionList)
                          ? item.personal.optionList
                          : [],
                        element.id
                      )
                    }}分
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="evaluation-card">
            <div class="evaluation-header">
              <div class="title">面试结果</div>
            </div>
            <div v-if="Array.isArray(item.subjectList)">
              <div v-for="element in item.subjectList.filter(
                    (subject) => subject.type === 'text' && subject.subjectGroup === '面试结果'
                  )" :key="element.id" class="between text">
                <div style="flex-shrink: 0; margin-right: 24px">
                  {{ element.title }}
                </div>
                <div class="title">
                  {{
                    getText(
                      Array.isArray(item.personal.optionList)
                        ? item.personal.optionList
                        : [],
                      element.id
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="evaluation-card">
            <div class="evaluation-header">
              <div class="title">面试建议及备注</div>
            </div>

            <div class="between text">
              <div style="flex-shrink: 0; margin-right: 24px">
                建议
              </div>
              <div class="title">
                {{
                  getAdvise(
                    Array.isArray(item.personal.optionList)
                      ? item.personal.optionList
                      : [],
                    item.subjectList.find(
                      (item) =>
                        item.type === "radio" &&
                        item.subjectGroup &&
                        item.subjectGroup.indexOf("建议") > -1
                    )
                  )
                }}
              </div>
            </div>

            <div class="between text">
              <div style="flex-shrink: 0; margin-right: 24px">
                备注
              </div>
              <div class="title" v-if="Array.isArray(item.subjectList)">

                <div v-for="element in item.subjectList.filter(
                      (subject) => subject.type === 'text' && subject.subjectGroup === '面试备注'
                    )" :key="element.id">

                  <div>
                    {{
                      getText(
                        Array.isArray(item.personal.optionList)
                          ? item.personal.optionList
                          : [],
                        element.id
                      )
                    }}
                  </div>
                </div>


              </div>
            </div>




          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="item" style="padding: 24px">
        <a-empty />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("interviewDetail", ["detail"]),

    // 评价列表
    remarksList() {
      return Array.isArray(this.detail.remarksList)
        ? this.detail.remarksList
        : [];
    },
  },

  methods: {
    getScore(list, subjectId) {
      // 面试者填写的分数列表，题目id
      const obj = list.find((item) => item.subjectId === subjectId);
      return obj ? obj.remark : 0;
    },
    getAllScore(list, subjectList) {
      let score = 0;
      subjectList.forEach((subject) => {
        const obj = list.find((item) => item.subjectId === subject.id);
        if (obj) {
          score += parseInt(obj.remark);
        }
      });
      return score;
    },
    // 获取用户填写的数据
    getText(list, subjectId) {
      // 面试者填写的分数列表，题目id
      const obj = list.find((item) => item.subjectId === subjectId);
      return obj ? obj.remark : "";
    },

    // 获取建议
    getAdvise(list, subject = {}) {
      const obj = list.find((item) => item.subjectId === subject.id);
      console.log("obj", subject);

      if (obj && Array.isArray(subject.optionList)) {
        const result = subject.optionList.find(
          (item) => item.optionValue === obj.remark
        );
        return result ? result.optionName : "";
      } else {
        return "";
      }
    },
  },
};
</script>


<style lang="less" scoped>
.list {
  border: 1px solid #e8e8e8;

  .header {
    line-height: 40px;
    background: rgba(24, 144, 255, 0.1);
    text-align: center;
    font-weight: bold;
  }

  .item {
    padding: 0 20px;
    border-bottom: 1px solid #e8e8e8;

    &:last-child {
      border-bottom-width: 0;
    }
  }
}

.title {
  color: #1890ff;
}

.evaluation {
  display: flex;

  .evaluation-card {
    flex: 1;
    border: 1px solid #f0f0f0;
    padding: 8px;
    margin-bottom: 8px;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    .evaluation-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid #f0f0f0;
    }

    .subject {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .text {
      border-bottom: 1px solid #f0f0f0;
      padding-bottom: 8px;
      margin-bottom: 8px;

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom-width: 0;
      }
    }
  }
}
</style>