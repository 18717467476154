<template>
  <div class="resume">
    <div class="menu">
      <a-radio-group
        :value="activeResumeType"
        @change="onChange"
        button-style="solid"
      >
        <a-radio-button value="base-info"> 基本信息 </a-radio-button>
        <a-radio-button
          value="camp"
          v-if="detail.deliveryChannel === 'school_enrollment'"
        >
          校内经历
        </a-radio-button>
        <a-radio-button
          value="internship"
          v-if="detail.deliveryChannel === 'school_enrollment'"
        >
          实习经历
        </a-radio-button>
        <a-radio-button value="work"> 工作经历 </a-radio-button>
        <a-radio-button value="school"> 教育经历 </a-radio-button>
        <a-radio-button value="family"> 家庭关系 </a-radio-button>
      </a-radio-group>
    </div>
    <BaseInfo v-if="activeResumeType === 'base-info'" />
    <Camp v-if="activeResumeType === 'camp'" />
    <Internship v-if="activeResumeType === 'internship'" />
    <Work v-if="activeResumeType === 'work'" />
    <School v-if="activeResumeType === 'school'" />
    <Family v-if="activeResumeType === 'family'" />
  </div>
</template>

<script>
import BaseInfo from "./base-info.vue";
import Camp from "./camp.vue";
import Internship from "./internship.vue";
import Work from "./work.vue";
import School from "./school.vue";
import Family from "./family.vue";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    BaseInfo,
    Camp,
    Internship,
    Work,
    School,
    Family,
  },
  computed: {
    ...mapState("interviewDetail", ["detail", "activeResumeType"]),
  },
  methods: {
    ...mapMutations("interviewDetail", ["setActiveResumeType"]),

    onChange(e) {
      this.setActiveResumeType(e.target.value);
    },
  },
};
</script>

<style lang="less" scoped>
.resume {
  padding: 12px;
  .menu {
    margin-bottom: 30px;
  }
}
</style>