<template>
  <div>
    <a-table bordered :data-source="list" :pagination="false">
      <a-table-column
        title="学校名称"
        data-index="school"
        align="center"
      ></a-table-column>
      <a-table-column title="起止时间" align="center">
        <template slot-scope="text">
          {{ text.timeFrom }}-{{ text.timeTo ? text.timeTo : "至今" }}
        </template>
      </a-table-column>

      <a-table-column title="专业" data-index="major" align="center" />

      <a-table-column title="学习形式" align="center">
        <template slot-scope="text">
          <DataDictFinder
            dictType="education_channel"
            :dictValue="text.channel"
          />
        </template>
      </a-table-column>

      <a-table-column title="学历" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="degree" :dictValue="text.education" />
        </template>
      </a-table-column>
      <a-table-column title="学位" align="center">
        <template slot-scope="text">
          <DataDictFinder
            dictType="academic_degree"
            :dictValue="text.academicDegree"
          />
        </template>
      </a-table-column>

      <a-table-column title="操作" align="center">
        <template slot-scope="text, record, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>

    <div class="center">
      <a-button @click="$close($route.path)">关闭</a-button>
    </div>

    <a-modal
      :visible="schoolVisible"
      title="添加教育经历"
      :footer="null"
      @cancel="cancel"
    >
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-form-item label="学校名称">
          <a-input
            v-decorator="[
              'school',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item label="起止时间">
          <a-range-picker
            v-decorator="[
              'time',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
            style="width: 100%"
          />
        </a-form-item>

        <a-form-item label="专业">
          <a-input
            v-decorator="[
              'major',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item label="学习形式">
          <a-select
            v-decorator="[
              'channel',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
          >
            <a-select-option
              v-for="item in education_channelList"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>

        <a-form-item label="学历">
          <a-select
            v-decorator="[
              'education',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
          >
            <a-select-option
              v-for="item in degreeList"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>

        <a-form-item label="学位">
          <a-select
            v-decorator="[
              'academicDegree',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
          >
            <a-select-option
              v-for="item in academic_degreeList"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">确认</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { edit } from "@/api/recruit";
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      form: this.$form.createForm(this),
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    education_channelList() {
      return this.findDataDict("education_channel");
    },
    degreeList() {
      return this.findDataDict("degree");
    },
    academic_degreeList() {
      return this.findDataDict("academic_degree");
    },

    ...mapState("interviewDetail", ["detail", "schoolVisible"]),
    list() {
      return Array.isArray(this.detail.hisEducationList)
        ? this.detail.hisEducationList
        : [];
    },
  },

  methods: {
    ...mapMutations("interviewDetail", ["setSchoolVisible"]),
    ...mapActions("interviewDetail", ["getDetail"]),

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);

          let timeFrom, timeTo;
          if (Array.isArray(values.time) && values.time.length === 2) {
            timeFrom = values.time[0].format("YYYY-MM-DD");
            timeTo = values.time[1].format("YYYY-MM-DD");
          }

          edit({
            ...this.detail,
            hisEducationList: [
              ...this.list,
              {
                ...values,
                timeFrom,
                timeTo,
                time: undefined,
              },
            ],
          }).then(() => {
            this.getDetail();
            this.cancel();
          });
        }
      });
    },

    cancel() {
      this.setSchoolVisible(false);
      this.form.resetFields();
    },

    remove(index) {
      this.list.splice(index, 1);
      edit({
        ...this.detail,
        hisEducationList: this.list,
      }).then(() => {
        this.getDetail();
      });
    },
  },
};
</script>


<style lang="less" scoped>
.center {
  margin-top: 80px;
}
</style>