<template>
  <div class="left">
    <img :src="icon" alt="" />
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
  },
};
</script>

<style lang="less" scoped>
.left {
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 24px;

  font-size: 14px;
  font-weight: bold;
  color: #1890ff;

  img {
    width: 16px;
    margin-right: 10px;
  }
}
</style>