var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"pagination":false}},[_c('a-table-column',{attrs:{"title":"关系","data-index":"relation","align":"center"}}),_c('a-table-column',{attrs:{"title":"姓名","data-index":"name","align":"center"}}),_c('a-table-column',{attrs:{"title":"年龄","data-index":"age","align":"center"}}),_c('a-table-column',{attrs:{"title":"工作单位","data-index":"company"}}),_c('a-table-column',{attrs:{"title":"职务","data-index":"post"}}),_c('a-table-column',{attrs:{"title":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text, record, index){return [_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remove(index)}}},[_vm._v("删除")])]}}])})],1),_c('div',{staticClass:"center"},[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1),_c('a-modal',{attrs:{"visible":_vm.familyVisible,"title":"添加家庭成员","footer":null},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 16 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"关系"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'relation',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'relation',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'name',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"年龄"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['age']),expression:"['age']"}],staticStyle:{"width":"100%"},attrs:{"min":0,"max":100}})],1),_c('a-form-item',{attrs:{"label":"工作单位"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['company']),expression:"['company']"}]})],1),_c('a-form-item',{attrs:{"label":"职务"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['post']),expression:"['post']"}]})],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("确认")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }