var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 16 },"colon":false,"labelAlign":"left"},on:{"submit":_vm.handleSubmit}},[_c('Title',{attrs:{"icon":require('@/assets/recruit/recruit2.png'),"title":"个人信息"}}),_c('a-row',[_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"证件照"}},[_c('Uploader',{attrs:{"file":_vm.detail.headerFile ? _vm.detail.headerFile : {}},on:{"change":_vm.onHeaderChange}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                initialValue: _vm.detail.name,
                rules: [{ required: true, message: '请输入！' }],
              },
            ]),expression:"[\n              'name',\n              {\n                initialValue: detail.name,\n                rules: [{ required: true, message: '请输入！' }],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"性别"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'sex',
              {
                initialValue: _vm.detail.sex,
                rules: [{ required: true, message: '请选择！' }],
              },
            ]),expression:"[\n              'sex',\n              {\n                initialValue: detail.sex,\n                rules: [{ required: true, message: '请选择！' }],\n              },\n            ]"}]},_vm._l((_vm.genderList),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"联系方式"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'mobile',
              {
                initialValue: _vm.detail.mobile,
                rules: [{ required: true, message: '请输入！' }],
              },
            ]),expression:"[\n              'mobile',\n              {\n                initialValue: detail.mobile,\n                rules: [{ required: true, message: '请输入！' }],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"邮箱地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
              {
                initialValue: _vm.detail.email,
                rules: [{ required: true, message: '请输入！' }],
              },
            ]),expression:"[\n              'email',\n              {\n                initialValue: detail.email,\n                rules: [{ required: true, message: '请输入！' }],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"出生日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'birthday',
              {
                initialValue: _vm.detail.birthday
                  ? _vm.moment2(_vm.detail.birthday)
                  : undefined,
              },
            ]),expression:"[\n              'birthday',\n              {\n                initialValue: detail.birthday\n                  ? moment2(detail.birthday)\n                  : undefined,\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"defaultPickerValue":_vm.moment2().subtract(25, 'years'),"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"政治面貌"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'political',
              {
                initialValue: _vm.detail.political,
              },
            ]),expression:"[\n              'political',\n              {\n                initialValue: detail.political,\n              },\n            ]"}]},_vm._l((_vm.politicalOutlookList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"身高(cm)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'height',
              {
                initialValue: _vm.detail.height,
              },
            ]),expression:"[\n              'height',\n              {\n                initialValue: detail.height,\n              },\n            ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"体重(kg)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'weight',
              {
                initialValue: _vm.detail.weight,
              },
            ]),expression:"[\n              'weight',\n              {\n                initialValue: detail.weight,\n              },\n            ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"血型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'bloodType',
              {
                initialValue: _vm.detail.bloodType,
              },
            ]),expression:"[\n              'bloodType',\n              {\n                initialValue: detail.bloodType,\n              },\n            ]"}]},_vm._l((_vm.bloodTypeList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"婚姻状况"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'maritalStatus',
              {
                initialValue: _vm.detail.maritalStatus,

                rules: [{ required: true, message: '请选择！' }],
              },
            ]),expression:"[\n              'maritalStatus',\n              {\n                initialValue: detail.maritalStatus,\n\n                rules: [{ required: true, message: '请选择！' }],\n              },\n            ]"}]},_vm._l((_vm.marrigeStatusList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"籍贯"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'nativePlace',
              {
                initialValue: _vm.detail.nativePlace,

                rules: [{ required: true, message: '请输入！' }],
              },
            ]),expression:"[\n              'nativePlace',\n              {\n                initialValue: detail.nativePlace,\n\n                rules: [{ required: true, message: '请输入！' }],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"户口所在地"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'registered',
              {
                initialValue: _vm.detail.registered,

                rules: [{ required: true, message: '请输入！' }],
              },
            ]),expression:"[\n              'registered',\n              {\n                initialValue: detail.registered,\n\n                rules: [{ required: true, message: '请输入！' }],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"现居住地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'address',
              {
                initialValue: _vm.detail.address,
                rules: [{ required: true, message: '请输入！' }],
              },
            ]),expression:"[\n              'address',\n              {\n                initialValue: detail.address,\n                rules: [{ required: true, message: '请输入！' }],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[(_vm.detail.deliveryChannel === 'school_enrollment')?_c('a-form-item',{attrs:{"label":"应届年份"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'freshYear',
              {
                initialValue: _vm.detail.freshYear,
                rules: [{ required: true, message: '请输入！' }],
              },
            ]),expression:"[\n              'freshYear',\n              {\n                initialValue: detail.freshYear,\n                rules: [{ required: true, message: '请输入！' }],\n              },\n            ]"}]})],1):_vm._e()],1)],1),_c('Padding',{attrs:{"size":"large"}}),_c('Title',{attrs:{"icon":require('@/assets/recruit/recruit5.png'),"title":"应聘信息"}}),_c('a-row',[(_vm.detail.deliveryChannel !== 'school_enrollment')?_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"是否离职"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'isDeparture',
              {
                initialValue:
                  typeof _vm.detail.isDeparture === 'number'
                    ? String(_vm.detail.isDeparture)
                    : undefined,
                rules: [{ required: true, message: '请选择！' }],
              },
            ]),expression:"[\n              'isDeparture',\n              {\n                initialValue:\n                  typeof detail.isDeparture === 'number'\n                    ? String(detail.isDeparture)\n                    : undefined,\n                rules: [{ required: true, message: '请选择！' }],\n              },\n            ]"}]},_vm._l((_vm.booleanList),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1):_vm._e(),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"到岗时间"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'arrivalTime',

              {
                initialValue: _vm.detail.arrivalTime,
                rules: [{ required: true, message: '请选择！' }],
              },
            ]),expression:"[\n              'arrivalTime',\n\n              {\n                initialValue: detail.arrivalTime,\n                rules: [{ required: true, message: '请选择！' }],\n              },\n            ]"}]},_vm._l((_vm.workTimeList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"应聘部门"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'deptId',
              {
                initialValue: _vm.detail.deptId,
                rules: [{ required: true, message: '请选择！' }],
              },
            ]),expression:"[\n              'deptId',\n              {\n                initialValue: detail.deptId,\n                rules: [{ required: true, message: '请选择！' }],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","filter-option":_vm.$selectFilterOption,"placeholder":""}},_vm._l((_vm.organizationList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.uniqueName))])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":_vm.detail.deliveryChannel === 'school_enrollment'
              ? '投递岗位'
              : '应聘岗位'}},[(_vm.detail.deliveryChannel === 'school_enrollment')?_c('div',[_c('a-button',{staticClass:"ellipsis",staticStyle:{"text-align":"left"},attrs:{"block":""},on:{"click":_vm.setPositionVisible}},[(_vm.detail.post)?_c('span',{attrs:{"title":_vm.detail.post}},[_vm._v(" "+_vm._s(_vm.detail.post)+" ")]):_c('span',[_vm._v("选择")])])],1):_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'post',
              {
                initialValue: _vm.detail.post,
                rules: [{ required: true, message: '请输入！' }],
              },
            ]),expression:"[\n              'post',\n              {\n                initialValue: detail.post,\n                rules: [{ required: true, message: '请输入！' }],\n              },\n            ]"}]}),_c('a-modal',{attrs:{"title":"选择岗位","visible":_vm.positionVisible,"footer":null},on:{"cancel":_vm.cancelPosition}},[_c('div',{staticClass:"position-modal"},[_c('div',{staticClass:"position-type-list"},_vm._l((_vm.positionTypeList),function(item){return _c('div',{key:item.value,staticClass:"position-type"},[_c('div',{staticClass:"position-type-title"},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.children)?_c('div',{staticClass:"position-value-list"},_vm._l((item.children),function(element){return _c('div',{key:element.value,staticClass:"position-value",class:_vm.selectedPosition.find(
                          (position) =>
                            position.type === item.name &&
                            position.value === element.name
                        )
                          ? 'selected'
                          : '',on:{"click":function($event){return _vm.selectPosition(item, element)}}},[_vm._v(" "+_vm._s(element.name)+" ")])}),0):_vm._e()])}),0),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancelPosition}},[_vm._v("关闭")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.savePosition}},[_vm._v("保存")])],1)],1)])])],1)],1),(_vm.detail.deliveryChannel !== 'school_enrollment')?_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{staticStyle:{"margin-bottom":"0"},attrs:{"label":"期望薪资(万/年)"}},[_c('a-form-item',{staticStyle:{"width":"46%","display":"inline-block"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'salaryMin',
                {
                  initialValue: _vm.detail.salaryMin,
                },
              ]),expression:"[\n                'salaryMin',\n                {\n                  initialValue: detail.salaryMin,\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"最低期望薪资"}})],1),_c('span',{staticStyle:{"width":"8%","display":"inline-block","text-align":"center"}},[_vm._v("-")]),_c('a-form-item',{staticStyle:{"width":"46%","display":"inline-block"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'salaryMax',
                {
                  initialValue: _vm.detail.salaryMax,
                },
              ]),expression:"[\n                'salaryMax',\n                {\n                  initialValue: detail.salaryMax,\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"最高期望薪资"}})],1)],1)],1):_vm._e(),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"最高学历"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'degree',
              {
                initialValue: _vm.detail.degree,
                rules: [{ required: true, message: '请选择！' }],
              },
            ]),expression:"[\n              'degree',\n              {\n                initialValue: detail.degree,\n                rules: [{ required: true, message: '请选择！' }],\n              },\n            ]"}]},_vm._l((_vm.degreeList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"是否应届"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'isFresh',
              {
                initialValue:
                  typeof _vm.detail.isFresh === 'number'
                    ? String(_vm.detail.isFresh)
                    : undefined,
                rules: [{ required: true, message: '请选择！' }],
              },
            ]),expression:"[\n              'isFresh',\n              {\n                initialValue:\n                  typeof detail.isFresh === 'number'\n                    ? String(detail.isFresh)\n                    : undefined,\n                rules: [{ required: true, message: '请选择！' }],\n              },\n            ]"}]},_vm._l((_vm.booleanList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),(_vm.detail.deliveryChannel === 'school_enrollment')?_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"是否服从调剂"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'isObeyDispensing',
              {
                initialValue:
                  typeof _vm.detail.isObeyDispensing === 'number'
                    ? String(_vm.detail.isObeyDispensing)
                    : undefined,
                rules: [{ required: true, message: '请选择！' }],
              },
            ]),expression:"[\n              'isObeyDispensing',\n              {\n                initialValue:\n                  typeof detail.isObeyDispensing === 'number'\n                    ? String(detail.isObeyDispensing)\n                    : undefined,\n                rules: [{ required: true, message: '请选择！' }],\n              },\n            ]"}]},_vm._l((_vm.booleanList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1):_vm._e(),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"兴趣爱好"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'interests',
              {
                initialValue: _vm.detail.interests,
              },
            ]),expression:"[\n              'interests',\n              {\n                initialValue: detail.interests,\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"投递时间"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'deliveryTime',
              {
                initialValue: _vm.detail.deliveryTime
                  ? _vm.moment2(_vm.detail.deliveryTime)
                  : undefined,
                rules: [{ required: true, message: '请选择！' }],
              },
            ]),expression:"[\n              'deliveryTime',\n              {\n                initialValue: detail.deliveryTime\n                  ? moment2(detail.deliveryTime)\n                  : undefined,\n                rules: [{ required: true, message: '请选择！' }],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"简历附件"}},[_c('Uploader',{attrs:{"file":_vm.detail.attachmentFile ? _vm.detail.attachmentFile : {}},on:{"change":_vm.onResumeChange}})],1)],1),_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"获得招聘信息渠道"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'recruitChannel',

              {
                initialValue: _vm.detail.recruitChannel,
                rules: [{ required: true, message: '请选择！' }],
              },
            ]),expression:"[\n              'recruitChannel',\n\n              {\n                initialValue: detail.recruitChannel,\n                rules: [{ required: true, message: '请选择！' }],\n              },\n            ]"}]},_vm._l((_vm.recruitChannelList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),(
          _vm.form.getFieldValue('recruitChannel') === 'introduction' ||
          _vm.detail.recruitChannel === 'introduction'
        )?_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"推荐人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'introductionName',
              {
                initialValue: _vm.detail.introductionName,
              },
            ]),expression:"[\n              'introductionName',\n              {\n                initialValue: detail.introductionName,\n              },\n            ]"}]})],1)],1):_vm._e(),(
          _vm.form.getFieldValue('recruitChannel') === 'channelOther' ||
          _vm.detail.recruitChannel === 'channelOther'
        )?_c('a-col',{attrs:{"lg":8,"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"其他"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'otherContent',
              {
                initialValue: _vm.detail.otherContent,
              },
            ]),expression:"[\n              'otherContent',\n              {\n                initialValue: detail.otherContent,\n              },\n            ]"}]})],1)],1):_vm._e()],1),_c('Padding',{attrs:{"size":"large"}}),_c('Title',{attrs:{"icon":require('@/assets/recruit/recruit3.png'),"title":"专业信息"}}),_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"专业特长"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'speciality',
              {
                initialValue: _vm.detail.speciality,
              },
            ]),expression:"[\n              'speciality',\n              {\n                initialValue: detail.speciality,\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"英语水平"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'englishLevel',

              {
                initialValue: _vm.detail.englishLevel,
              },
            ]),expression:"[\n              'englishLevel',\n\n              {\n                initialValue: detail.englishLevel,\n              },\n            ]"}]},_vm._l((_vm.englishLevelList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),(
          _vm.form.getFieldValue('englishLevel') === 'TOEFL' ||
          _vm.form.getFieldValue('englishLevel') === 'IELTS' ||
          _vm.detail.englishLevel === 'TOEFL' ||
          _vm.detail.englishLevel === 'IELTS'
        )?_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"分数"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'englishScore',
              {
                initialValue: _vm.detail.englishScore,
                rules: [{ required: true, message: '请输入！' }],
              },
            ]),expression:"[\n              'englishScore',\n              {\n                initialValue: detail.englishScore,\n                rules: [{ required: true, message: '请输入！' }],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"min":1}})],1)],1):_vm._e(),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"计算机水平"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'computerLevel',

              {
                initialValue: _vm.detail.computerLevel,
              },
            ]),expression:"[\n              'computerLevel',\n\n              {\n                initialValue: detail.computerLevel,\n              },\n            ]"}]},_vm._l((_vm.computerLevelList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"职称证书"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'professionalCert',
              {
                initialValue: _vm.detail.professionalCert,
              },
            ]),expression:"[\n              'professionalCert',\n              {\n                initialValue: detail.professionalCert,\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"注册证书1"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'registeredCert1',
              {
                initialValue: _vm.detail.registeredCert1,
              },
            ]),expression:"[\n              'registeredCert1',\n              {\n                initialValue: detail.registeredCert1,\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"注册证书2"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'registeredCert2',
              {
                initialValue: _vm.detail.registeredCert2,
              },
            ]),expression:"[\n              'registeredCert2',\n              {\n                initialValue: detail.registeredCert2,\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"注册证书3"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'registeredCert3',
              {
                initialValue: _vm.detail.registeredCert3,
              },
            ]),expression:"[\n              'registeredCert3',\n              {\n                initialValue: detail.registeredCert3,\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"其他证书"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'registeredCertOther',
              {
                initialValue: _vm.detail.registeredCertOther,
              },
            ]),expression:"[\n              'registeredCertOther',\n              {\n                initialValue: detail.registeredCertOther,\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"所获奖项1"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'award1',
              {
                initialValue: _vm.detail.award1,
              },
            ]),expression:"[\n              'award1',\n              {\n                initialValue: detail.award1,\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"所获奖项2"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'award2',
              {
                initialValue: _vm.detail.award2,
              },
            ]),expression:"[\n              'award2',\n              {\n                initialValue: detail.award2,\n              },\n            ]"}]})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }