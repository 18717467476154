var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('div',{staticClass:"header"},[_vm._v("面试评价")]),(_vm.remarksList.length > 0)?_vm._l((_vm.remarksList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"left",staticStyle:{"padding":"10px 0"}},[_c('span',{staticClass:"title",staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.title))]),_c('span',{staticStyle:{"margin-left":"auto"}},[_vm._v("面试官："+_vm._s(item.personal ? item.personal.userName + "," + item.personal.juryUserNames : ""))]),_c('span',{staticStyle:{"margin-left":"8px"}},[_vm._v("面试时间："+_vm._s(item.personal ? item.personal.createAt : ""))])]),_c('div',{staticClass:"evaluation"},[_c('div',{staticClass:"evaluation-card"},[_c('div',{staticClass:"evaluation-header"},[_c('div',{staticClass:"title"},[_vm._v("面试评分")]),(Array.isArray(item.subjectList))?_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.getAllScore( Array.isArray(item.personal.optionList) ? item.personal.optionList : [], item.subjectList.filter( (subject) => subject.subjectGroup === "面试评分" ) ))+"分 ")]):_vm._e()]),_c('div',[(Array.isArray(item.subjectList))?_c('div',_vm._l((item.subjectList.filter(
                    (subject) => subject.subjectGroup === '面试评分'
                  )),function(element){return _c('div',{key:element.id,staticClass:"between subject"},[_c('div',[_vm._v(_vm._s(element.title))]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.getScore( Array.isArray(item.personal.optionList) ? item.personal.optionList : [], element.id ))+"分 ")])])}),0):_vm._e()])]),_c('div',{staticClass:"evaluation-card"},[_vm._m(0,true),(Array.isArray(item.subjectList))?_c('div',_vm._l((item.subjectList.filter(
                  (subject) => subject.type === 'text' && subject.subjectGroup === '面试结果'
                )),function(element){return _c('div',{key:element.id,staticClass:"between text"},[_c('div',{staticStyle:{"flex-shrink":"0","margin-right":"24px"}},[_vm._v(" "+_vm._s(element.title)+" ")]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.getText( Array.isArray(item.personal.optionList) ? item.personal.optionList : [], element.id ))+" ")])])}),0):_vm._e()]),_c('div',{staticClass:"evaluation-card"},[_vm._m(1,true),_c('div',{staticClass:"between text"},[_c('div',{staticStyle:{"flex-shrink":"0","margin-right":"24px"}},[_vm._v(" 建议 ")]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.getAdvise( Array.isArray(item.personal.optionList) ? item.personal.optionList : [], item.subjectList.find( (item) => item.type === "radio" && item.subjectGroup && item.subjectGroup.indexOf("建议") > -1 ) ))+" ")])]),_c('div',{staticClass:"between text"},[_c('div',{staticStyle:{"flex-shrink":"0","margin-right":"24px"}},[_vm._v(" 备注 ")]),(Array.isArray(item.subjectList))?_c('div',{staticClass:"title"},_vm._l((item.subjectList.filter(
                    (subject) => subject.type === 'text' && subject.subjectGroup === '面试备注'
                  )),function(element){return _c('div',{key:element.id},[_c('div',[_vm._v(" "+_vm._s(_vm.getText( Array.isArray(item.personal.optionList) ? item.personal.optionList : [], element.id ))+" ")])])}),0):_vm._e()])])])])}):[_c('div',{staticClass:"item",staticStyle:{"padding":"24px"}},[_c('a-empty')],1)]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"evaluation-header"},[_c('div',{staticClass:"title"},[_vm._v("面试结果")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"evaluation-header"},[_c('div',{staticClass:"title"},[_vm._v("面试建议及备注")])])
}]

export { render, staticRenderFns }