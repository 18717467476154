<template>
  <div class="interview">
    <div class="wrapper">
      <div class="base-info">
        <img
          v-if="detail.headerFile"
          :src="detail.headerFile.completePath"
          class="avatar"
          alt="证件照"
        />
        <div class="">
          <div class="name">
            <span>{{ detail.name }}</span>
            <a-tag
              class="clickable"
              v-if="detail.codeSukang"
              @click="open(detail.codeSukangFile.completePath)"
              color="green"
              >健康码</a-tag
            >
            <a-tag
              class="clickable"
              v-if="detail.codeTravel"
              @click="open(detail.codeTravelFile.completePath)"
              color="green"
              >行程码</a-tag
            >

            <a-tooltip title="刷新数据">
              <a href="#" @click.prevent="refresh">
                <a-icon type="redo" />
              </a>
            </a-tooltip>
          </div>
          <div class="left description">
            <template v-if="age !== null">
              <span>
                {{ age }}
                岁</span
              >
              <span class="dot">·</span>
            </template>

            <DataDictFinder dictType="degree" :dictValue="detail.degree" />
            <span class="dot">·</span>
            <template v-if="workingYears !== null">
              <span>{{ workingYears }}年工作经验</span>
              <span class="dot">·</span>
            </template>
            <span>
              {{ detail.isDeparture === 1 ? "离职" : "在职" }}
            </span>
          </div>
          <div class="description">
            <a-icon type="mobile" style="color: #1890ff; margin-right: 8px" />
            <span style="padding-right: 32px">{{ detail.mobile }}</span>
            <a-icon type="mail" style="color: #1890ff; margin-right: 8px" />
            <span>{{ detail.email }}</span>
          </div>
        </div>

        <EvaluationQrcode v-if="detail.id" :detail="detail" />
        <InterviewQrcode v-if="detail.id" :detail="detail" />
      </div>

          <a-radio-group button-style="solid" v-model="activeIndex">
            <a-radio-button
                 v-for="(item, index) in interviewList"
                :key="item.id"
                title="切换阶段"
                :value='index'
            > 
                     <DataDictFinder
                  dictType="recruit_phase"
                  :dictValue="item.phase"
                />
             </a-radio-button>
          </a-radio-group>

            <Padding size="large" />
     
      <a-descriptions bordered size="small" :column="2">
        <a-descriptions-item :span="2">
          <div slot="label" class="center">面试阶段</div>
          <div class="value">

                   <DataDictFinder
                  dictType="recruit_phase"
                  :dictValue="interview.phase"
                />

     
          </div>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
          <div slot="label" class="center">
            <span>面试官</span>
          </div>
          <div class="left">
            <div class="hr" v-for="item in hrList" :key="item.phase">
              <span style="padding-right: 12px; font-weight: bold">
                <DataDictFinder
                  dictType="recruit_phase"
                  :dictValue="item.phase"
                />
              </span>
              <template v-if="Array.isArray(item.list)">
                <a-tag
                  color="blue"
                  v-for="element in item.list"
                  :key="element.userId"
                >
                  {{ element.userName }}
                </a-tag>
              </template>
            </div>

            <a-button
              type="primary"
              size="small"
              style="margin-left: auto"
              @click="inviteHR"
              >修改</a-button
            >
          </div>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
          <div slot="label" class="center">应聘者确认</div>
          <div>
            <DataDictFinder
              dictType="confirmStatus"
              :dictValue="interview.confirmStatus"
            />
          </div>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
          <div slot="label" class="center">面试状态</div>
          <div>
            <DataDictFinder
              dictType="resultStatus"
              :dictValue="interview.resultStatus"
            />
          </div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">面试时间</div>
          <div>{{ interview.interviewDate }}</div>
        </a-descriptions-item>

        <a-descriptions-item>
          <div slot="label" class="center">面试形式</div>
          <div>
            <DataDictFinder
              dictType="interviewMethod"
              :dictValue="interview.method"
            />
          </div>
        </a-descriptions-item>

        <a-descriptions-item :span="2" v-if="interview.method === 'offline'">
          <div slot="label" class="center">面试地点</div>
          <div>{{ interview.interviewAddress }}</div>
        </a-descriptions-item>

        <a-descriptions-item :span="2" v-if="interview.method === 'online'">
          <div slot="label" class="center">腾讯会议号</div>
          <div>{{ interview.onlineRoomId }}</div>
        </a-descriptions-item>

        <a-descriptions-item :span="2">
          <div slot="label" class="center">备注</div>
          <div>{{ interview.remarks }}</div>
        </a-descriptions-item>
      </a-descriptions>

      <Padding size="large" />
      <Evaluation />
      <Padding size="large" />

      <div class="list">
        <div class="header">面试记录</div>
        <div class="item" v-for="(item, index) in log" :key="index">
          <span class="date">{{ item.logDate }}</span>
          <span>{{ item.businessContent }}</span>
        </div>
      </div>
      <Padding size="large" />

      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
        </a-space>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapState, mapActions } from "vuex";
import EvaluationQrcode from "./evaluation-qrcode.vue";
import InterviewQrcode from "./interview-qrcode.vue";
import Evaluation from "./evaluation.vue";

export default {
  components: {
    EvaluationQrcode,
    InterviewQrcode,
    Evaluation,
  },

  data() {
    return {
      activeIndex: -1,
      interview: {}, // 当前渲染的面试
    };
  },

  watch: {
    interviewList() {
      if (this.interviewList.length > 0) {
        this.activeIndex = this.interviewList.length - 1;
      }
    },
    activeIndex(newValue) {
      if (newValue !== -1) {
        this.interview = this.interviewList[newValue];
      }
    }
  },

  mounted() {
    if (this.interviewList.length > 0) {
      this.activeIndex = this.interviewList.length - 1;
      // this.interview = this.interviewList[this.interviewList.length - 1];
    }
  },

  computed: {
    ...mapState("interviewDetail", ["detail", "log"]),

    ...mapGetters("interviewDetail", ["interviewList", "hrList"]),

    age() {
      if (this.detail.birthday) {
        return (
          new Date().getFullYear() -
          parseInt(moment(this.detail.birthday).format("YYYY"))
        );
      } else {
        return null;
      }
    },
    // 工作经验
    workingYears() {
      if (
        Array.isArray(this.detail.hisWorkList) &&
        this.detail.hisWorkList.length > 0
      ) {
        let thisYear = new Date().getFullYear();
        let minYear = thisYear;
        this.detail.hisWorkList.forEach((item) => {
          const year = parseInt(moment(item.timeFrom).format("YYYY"));
          if (year < minYear) {
            minYear = year;
          }
        });
        return thisYear - minYear;
      } else {
        return null;
      }
    },
  },

  methods: {
    ...mapActions("interviewDetail", ["getDetail", "getLog"]),

    refresh() {
      this.getDetail();
      this.getLog();
    },

    open(url) {
      window.open(url);
    },

    // 默认显示最新的面试阶段，用户可以切换查看评价
    // togglePhase(e) {
    //   this.activeIndex = e.target.value;
    //   this.interview = this.interviewList[e.target.value];
    // },

    // 邀请面试官
    inviteHR() {
      this.$router.push(
        "/human-resources/recruit/invite-hr?ids=" + this.detail.id
      );
    },
  },
};
</script>


<style lang="less" scoped>
.interview {
  padding: 12px;
}

.invite-title {
  position: relative;

  .invite-button {
    position: absolute;
    top: -2px;
    right: 0;
  }
}

.base-info {
  display: flex;
  align-items: center;
  margin-bottom: 37px;

  .avatar {
    margin-right: 20px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  .clickable {
    cursor: pointer;
  }

  .name {
    margin-bottom: 8px;
    span:first-child {
      font-weight: bold;
      font-size: 16px;
      padding-right: 8px;
    }
  }

  .description {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    opacity: 0.75;
    margin-bottom: 8px;

    .dot {
      margin: 0 4px;
    }
  }
}

.hr {
  margin-right: 48px;
  // margin-bottom: 8px;
  display: flex;
  align-items: center;

  // &:last-child {
  //   margin-bottom: 0;
  // }
}

.list {
  border: 1px solid #e8e8e8;

  .header {
    background: rgba(24, 144, 255, 0.1);
    text-align: center;
    font-weight: bold;
    line-height: 40px;
  }

  .item {
    line-height: 40px;
    padding: 0 20px;
    border-bottom: 1px solid #e8e8e8;
    &:last-child {
      border-bottom-width: 0;
    }
    .date {
      padding-right: 20px;
    }
  }
}

.value {
  display: flex;
  align-items: center;
}

.phase {
  display: flex;
  align-items: center;

  & > div {
    cursor: pointer;
    margin-right: 48px;
    position: relative;
    left: 16px;
    &::before {
      content: "";
      position: absolute;
      top: 3.5px;
      left: -16px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 1px solid #999;
    }

    .pass {
      position: absolute;
      top: 3.5px;
      left: -16px;
      height: 12px;
      width: 12px;
      background-color: rgb(22, 208, 22);
      border: 1px solid rgb(22, 208, 22);
      border-radius: 50%;
      font-size: 8px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .active {
    color: #1890ff;

    &::before {
      border: 1px solid #1890ff;
    }

    .pass {
      background-color: #1890ff;
      border: 1px solid #1890ff;
    }
  }
}
</style>