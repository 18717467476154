<template>
  <div>
    <div class="pane">
      <div>
        <div class="title">
          {{ detail.name }}
        </div>
        <a-tabs
          v-model="active"
          :tabBarStyle="{
            margin: '0',
          }"
        >
          <a-tab-pane key="1" tab="面试信息"> </a-tab-pane>
          <a-tab-pane key="2" tab="简历管理"> </a-tab-pane>
        </a-tabs>
      </div>

      <div class="tool">
        <div
          class="btn"
          v-if="
            active === '2' &&
            activeResumeType === 'camp' &&
            detail.deliveryChannel === 'school_enrollment'
          "
          @click="addCamp"
        >
          <img src="@/assets/recruit/recruit8.png" alt="" />
          <span style="color: #52c41a">新增校内经历</span>
        </div>

        <div
          class="btn"
          v-if="
            active === '2' &&
            activeResumeType === 'internship' &&
            detail.deliveryChannel === 'school_enrollment'
          "
          @click="addInternship"
        >
          <img src="@/assets/recruit/recruit8.png" alt="" />
          <span style="color: #52c41a">新增实习经历</span>
        </div>

        <div
          class="btn"
          v-if="active === '2' && activeResumeType === 'work'"
          @click="addWork"
        >
          <img src="@/assets/recruit/recruit8.png" alt="" />
          <span style="color: #52c41a">新增工作经历</span>
        </div>

        <div
          class="btn"
          v-if="active === '2' && activeResumeType === 'school'"
          @click="addSchool"
        >
          <img src="@/assets/recruit/recruit8.png" alt="" />
          <span style="color: #52c41a">新增教育经历</span>
        </div>
        <div
          class="btn"
          v-if="active === '2' && activeResumeType === 'family'"
          @click="addFamily"
        >
          <img src="@/assets/recruit/recruit8.png" alt="" />
          <span style="color: #52c41a">新增家庭成员</span>
        </div>

        <Invite
          :user="detail"
          :visible="inviteVisible"
          @setVisible="(visible) => (inviteVisible = visible)"
          @refresh="refresh"
        />

        <Hire
          :user="detail"
          :visible="hireVisible"
          @setVisible="(visible) => (hireVisible = visible)"
          @refresh="refresh"
        />

        <div
          class="btn"
          @click="inviteVisible = true"
          v-if="$getPermission('/human-resources/recruit/invite')"
        >
          <img src="@/assets/recruit/recruit6.png" alt="" />
          <span style="color: #1890ff">邀请面试</span>
        </div>
        <div
          class="btn"
          @click="hireVisible = true"
          v-if="$getPermission('/human-resources/recruit/hire')"
        >
          <img src="@/assets/recruit/recruit7.png" alt="" />
          <span style="color: #ff8c18">录用通知</span>
        </div>
      </div>
    </div>

    <a-card class="container">
      <Interview v-show="active === '1'" />
      <Resume v-show="active === '2'" />
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { mapActions, mapMutations, mapState } from "vuex";
import Interview from "./components/interview.vue";
import Resume from "./components/resume.vue";

import Invite from "./components/invite.vue";
import Hire from "./components/hire.vue";

export default {
  name: "editRecruit",

  mixins: [watermark],

  components: {
    Interview,
    Resume,

    Invite,
    Hire,
  },

  data() {
    return {
      active: "1",
      inviteVisible: false,
      hireVisible: false,
    };
  },

  computed: {
    ...mapState("interviewDetail", ["id", "detail", "activeResumeType"]),
  },

  activated() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.setId(id);
      this.getDetail();
      this.getLog();
    }
  },

  methods: {
    ...mapMutations("interviewDetail", [
      "setId",
      "setCampVisible",
      "setInternshipVisible",
      "setWorkVisible",
      "setFamilyVisible",
      "setSchoolVisible",
    ]),
    ...mapActions("interviewDetail", ["getDetail", "getLog"]),
    addCamp() {
      this.setCampVisible(true);
    },
    addInternship() {
      this.setInternshipVisible(true);
    },
    addWork() {
      this.setWorkVisible(true);
    },
    addFamily() {
      this.setFamilyVisible(true);
    },
    addSchool() {
      this.setSchoolVisible(true);
    },

    refresh() {
      this.getDetail();
      this.getLog();
    },
  },
};
</script>

<style lang="less" scoped>
.pane {
  background-color: #fff;
  padding: 16px 32px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 8px;
  }
  .tool {
    display: flex;
    align-items: center;

    .btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #1890ff;
      margin-left: 24px;
      cursor: pointer;

      img {
        height: 27px;
        width: 27px;
        margin-bottom: 4px;
      }
    }
  }
}
</style>
