<template>
  <div>
    <a-table bordered :data-source="list" :pagination="false">
      <a-table-column
        title="校内职务"
        data-index="position"
        align="center"
      ></a-table-column>
      <a-table-column title="起止时间" align="center">
        <template slot-scope="text">
          {{ text.timeFrom }}-{{ text.timeTo ? text.timeTo : "至今" }}
        </template>
      </a-table-column>

      <a-table-column
        title="负责具体内容"
        data-index="jobContent"
        align="center"
      />

      <a-table-column title="操作" align="center">
        <template slot-scope="text, record, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>

    <div class="center">
      <a-button @click="$close($route.path)">关闭</a-button>
    </div>

    <a-modal
      :visible="campVisible"
      title="添加实习经历"
      :footer="null"
      @cancel="cancel"
    >
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-form-item label="校内职务">
          <a-input
            v-decorator="[
              'position',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item label="起止时间">
          <a-range-picker
            v-decorator="[
              'time',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
            style="width: 100%"
          />
        </a-form-item>

        <a-form-item label="负责具体内容">
          <a-input
            v-decorator="[
              'jobContent',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">确认</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { edit } from "@/api/recruit";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      form: this.$form.createForm(this),
    };
  },

  computed: {
    ...mapState("interviewDetail", ["detail", "campVisible"]),
    list() {
      return Array.isArray(this.detail.hisCampusList)
        ? this.detail.hisCampusList
        : [];
    },
  },

  methods: {
    ...mapMutations("interviewDetail", ["setCampVisible"]),
    ...mapActions("interviewDetail", ["getDetail"]),

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);

          let timeFrom, timeTo;
          if (Array.isArray(values.time) && values.time.length === 2) {
            timeFrom = values.time[0].format("YYYY-MM-DD");
            timeTo = values.time[1].format("YYYY-MM-DD");
          }

          edit({
            ...this.detail,
            hisCampusList: [
              ...this.list,
              {
                ...values,
                timeFrom,
                timeTo,
                time: undefined,
              },
            ],
          }).then(() => {
            this.getDetail();
            this.cancel();
          });
        }
      });
    },

    cancel() {
      this.setCampVisible(false);
      this.form.resetFields();
    },

    remove(index) {
      this.list.splice(index, 1);
      edit({
        ...this.detail,
        hisCampusList: this.list,
      }).then(() => {
        this.getDetail();
      });
    },
  },
};
</script>


<style lang="less" scoped>
.center {
  margin-top: 80px;
}
</style>