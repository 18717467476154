var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"pagination":false}},[_c('a-table-column',{attrs:{"title":"单位名称","data-index":"company","align":"center"}}),_c('a-table-column',{attrs:{"title":"起止时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_vm._v(" "+_vm._s(text.timeFrom)+"-"+_vm._s(text.timeTo ? text.timeTo : "至今")+" ")]}}])}),_c('a-table-column',{attrs:{"title":"实习内容","data-index":"internshipContent","align":"center"}}),_c('a-table-column',{attrs:{"title":"实习感悟","data-index":"internshipPerception","align":"center"}}),_c('a-table-column',{attrs:{"title":"带教老师","data-index":"leadTeacher","align":"center"}}),_c('a-table-column',{attrs:{"title":"带教老师联系方式","data-index":"leadTeacherMobile","align":"center"}}),_c('a-table-column',{attrs:{"title":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text, record, index){return [_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remove(index)}}},[_vm._v("删除")])]}}])})],1),_c('div',{staticClass:"center"},[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1),_c('a-modal',{attrs:{"visible":_vm.internshipVisible,"title":"添加实习经历","footer":null},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 16 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"单位名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'company',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'company',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"起止时间"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'time',
            { rules: [{ required: true, message: '请选择！' }] },
          ]),expression:"[\n            'time',\n            { rules: [{ required: true, message: '请选择！' }] },\n          ]"}],staticStyle:{"width":"100%"}})],1),_c('a-form-item',{attrs:{"label":"实习内容"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'internshipContent',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'internshipContent',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"实习感悟"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'internshipPerception',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'internshipPerception',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"带教老师"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'leadTeacher',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'leadTeacher',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"带教老师联系方式"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'leadTeacherMobile',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'leadTeacherMobile',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("确认")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }