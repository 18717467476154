<template>
  <div>
    <a-form
      :form="form"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
      :colon="false"
      @submit="handleSubmit"
      labelAlign="left"
    >
      <Title
        :icon="require('@/assets/recruit/recruit2.png')"
        title="个人信息"
      />

      <a-row>
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="证件照">
            <Uploader
              :file="detail.headerFile ? detail.headerFile : {}"
              @change="onHeaderChange"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="姓名">
            <a-input
              v-decorator="[
                'name',
                {
                  initialValue: detail.name,
                  rules: [{ required: true, message: '请输入！' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="性别">
            <a-radio-group
              v-decorator="[
                'sex',
                {
                  initialValue: detail.sex,
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]"
            >
              <a-radio
                v-for="item in genderList"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-radio
              >
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="联系方式">
            <a-input
              v-decorator="[
                'mobile',
                {
                  initialValue: detail.mobile,
                  rules: [{ required: true, message: '请输入！' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="邮箱地址">
            <a-input
              v-decorator="[
                'email',
                {
                  initialValue: detail.email,
                  rules: [{ required: true, message: '请输入！' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="出生日期">
            <a-date-picker
              style="width: 100%"
              :defaultPickerValue="moment2().subtract(25, 'years')"
              placeholder=""
              v-decorator="[
                'birthday',
                {
                  initialValue: detail.birthday
                    ? moment2(detail.birthday)
                    : undefined,
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="政治面貌">
            <a-select
              v-decorator="[
                'political',
                {
                  initialValue: detail.political,
                },
              ]"
            >
              <a-select-option
                v-for="item in politicalOutlookList"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="身高(cm)">
            <a-input-number
              v-decorator="[
                'height',
                {
                  initialValue: detail.height,
                },
              ]"
              style="width: 100%"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="体重(kg)">
            <a-input-number
              v-decorator="[
                'weight',
                {
                  initialValue: detail.weight,
                },
              ]"
              style="width: 100%"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="血型">
            <a-select
              v-decorator="[
                'bloodType',
                {
                  initialValue: detail.bloodType,
                },
              ]"
            >
              <a-select-option
                v-for="item in bloodTypeList"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="婚姻状况">
            <a-select
              v-decorator="[
                'maritalStatus',
                {
                  initialValue: detail.maritalStatus,

                  rules: [{ required: true, message: '请选择！' }],
                },
              ]"
            >
              <a-select-option
                v-for="item in marrigeStatusList"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="籍贯">
            <a-input
              v-decorator="[
                'nativePlace',
                {
                  initialValue: detail.nativePlace,

                  rules: [{ required: true, message: '请输入！' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="户口所在地">
            <a-input
              v-decorator="[
                'registered',
                {
                  initialValue: detail.registered,

                  rules: [{ required: true, message: '请输入！' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="现居住地址">
            <a-input
              v-decorator="[
                'address',
                {
                  initialValue: detail.address,
                  rules: [{ required: true, message: '请输入！' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item v-if="detail.deliveryChannel === 'school_enrollment'" label="应届年份">
            <a-input
              v-decorator="[
                'freshYear',
                {
                  initialValue: detail.freshYear,
                  rules: [{ required: true, message: '请输入！' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <Padding size="large" />
      <Title
        :icon="require('@/assets/recruit/recruit5.png')"
        title="应聘信息"
      />

      <a-row>
        <a-col
          :lg="8"
          :md="8"
          :sm="24"
          v-if="detail.deliveryChannel !== 'school_enrollment'"
        >
          <a-form-item label="是否离职">
            <a-radio-group
              v-decorator="[
                'isDeparture',
                {
                  initialValue:
                    typeof detail.isDeparture === 'number'
                      ? String(detail.isDeparture)
                      : undefined,
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]"
            >
              <a-radio
                v-for="item in booleanList"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-radio
              >
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="到岗时间">
            <a-select
              v-decorator="[
                'arrivalTime',

                {
                  initialValue: detail.arrivalTime,
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]"
            >
              <a-select-option
                v-for="item in workTimeList"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="应聘部门">
            <a-select
              show-search
              :filter-option="$selectFilterOption"
              v-decorator="[
                'deptId',
                {
                  initialValue: detail.deptId,
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]"
              style="width: 100%"
              placeholder=""
            >
              <a-select-option
                v-for="item in organizationList"
                :key="item.id"
                :value="item.id"
                >{{ item.uniqueName }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item
            :label="
              detail.deliveryChannel === 'school_enrollment'
                ? '投递岗位'
                : '应聘岗位'
            "
          >
            <div v-if="detail.deliveryChannel === 'school_enrollment'">
              <a-button
                block
                @click="setPositionVisible"
                style="text-align: left"
                class="ellipsis"
              >
                <span v-if="detail.post" :title="detail.post">
                  {{ detail.post }}
                </span>
                <span v-else>选择</span>
              </a-button>
            </div>

            <a-input
              v-else
              v-decorator="[
                'post',
                {
                  initialValue: detail.post,
                  rules: [{ required: true, message: '请输入！' }],
                },
              ]"
            />

            <a-modal
              title="选择岗位"
              :visible="positionVisible"
              :footer="null"
              @cancel="cancelPosition"
            >
              <div class="position-modal">
                <div class="position-type-list">
                  <div
                    class="position-type"
                    v-for="item in positionTypeList"
                    :key="item.value"
                  >
                    <div class="position-type-title">
                      {{ item.name }}
                    </div>
                    <div class="position-value-list" v-if="item.children">
                      <div
                        class="position-value"
                        v-for="element in item.children"
                        :key="element.value"
                        @click="selectPosition(item, element)"
                        :class="
                          selectedPosition.find(
                            (position) =>
                              position.type === item.name &&
                              position.value === element.name
                          )
                            ? 'selected'
                            : ''
                        "
                      >
                        {{ element.name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="right">
                  <a-space>
                    <a-button @click="cancelPosition">关闭</a-button>
                    <a-button type="primary" @click="savePosition"
                      >保存</a-button
                    >
                  </a-space>
                </div>
              </div>
            </a-modal>
          </a-form-item>
        </a-col>

        <a-col
          :lg="8"
          :md="8"
          :sm="24"
          v-if="detail.deliveryChannel !== 'school_enrollment'"
        >
          <a-form-item label="期望薪资(万/年)" style="margin-bottom: 0">
            <a-form-item style="width: 46%; display: inline-block">
              <a-input-number
                placeholder="最低期望薪资"
                v-decorator="[
                  'salaryMin',
                  {
                    initialValue: detail.salaryMin,
                  },
                ]"
                style="width: 100%"
              />
            </a-form-item>

            <span style="width: 8%; display: inline-block; text-align: center"
              >-</span
            >

            <a-form-item style="width: 46%; display: inline-block">
              <a-input-number
                placeholder="最高期望薪资"
                v-decorator="[
                  'salaryMax',
                  {
                    initialValue: detail.salaryMax,
                  },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="最高学历">
            <a-select
              v-decorator="[
                'degree',
                {
                  initialValue: detail.degree,
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]"
            >
              <a-select-option
                v-for="item in degreeList"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="是否应届">
            <a-select
              v-decorator="[
                'isFresh',
                {
                  initialValue:
                    typeof detail.isFresh === 'number'
                      ? String(detail.isFresh)
                      : undefined,
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]"
            >
              <a-select-option
                v-for="item in booleanList"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col
          :lg="8"
          :md="8"
          :sm="24"
          v-if="detail.deliveryChannel === 'school_enrollment'"
        >
          <a-form-item label="是否服从调剂">
            <a-select
              v-decorator="[
                'isObeyDispensing',
                {
                  initialValue:
                    typeof detail.isObeyDispensing === 'number'
                      ? String(detail.isObeyDispensing)
                      : undefined,
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]"
            >
              <a-select-option
                v-for="item in booleanList"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="兴趣爱好">
            <a-input
              v-decorator="[
                'interests',
                {
                  initialValue: detail.interests,
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="投递时间">
            <a-date-picker
              style="width: 100%"
              placeholder=""
              v-decorator="[
                'deliveryTime',
                {
                  initialValue: detail.deliveryTime
                    ? moment2(detail.deliveryTime)
                    : undefined,
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="简历附件">
            <Uploader
              :file="detail.attachmentFile ? detail.attachmentFile : {}"
              @change="onResumeChange"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="8" :md="8" :sm="24">
          <a-form-item label="获得招聘信息渠道">
            <a-select
              v-decorator="[
                'recruitChannel',

                {
                  initialValue: detail.recruitChannel,
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]"
            >
              <a-select-option
                v-for="item in recruitChannelList"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col
          :lg="8"
          :md="8"
          :sm="24"
          v-if="
            form.getFieldValue('recruitChannel') === 'introduction' ||
            detail.recruitChannel === 'introduction'
          "
        >
          <a-form-item label="推荐人">
            <a-input
              v-decorator="[
                'introductionName',
                {
                  initialValue: detail.introductionName,
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col
          :lg="8"
          :md="8"
          :sm="24"
          v-if="
            form.getFieldValue('recruitChannel') === 'channelOther' ||
            detail.recruitChannel === 'channelOther'
          "
        >
          <a-form-item label="其他">
            <a-input
              v-decorator="[
                'otherContent',
                {
                  initialValue: detail.otherContent,
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <Padding size="large" />

      <Title
        :icon="require('@/assets/recruit/recruit3.png')"
        title="专业信息"
      />

      <a-row>
        <a-col :span="8">
          <a-form-item label="专业特长">
            <a-input
              v-decorator="[
                'speciality',
                {
                  initialValue: detail.speciality,
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="英语水平">
            <a-select
              v-decorator="[
                'englishLevel',

                {
                  initialValue: detail.englishLevel,
                },
              ]"
            >
              <a-select-option
                v-for="item in englishLevelList"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col
          :span="8"
          v-if="
            form.getFieldValue('englishLevel') === 'TOEFL' ||
            form.getFieldValue('englishLevel') === 'IELTS' ||
            detail.englishLevel === 'TOEFL' ||
            detail.englishLevel === 'IELTS'
          "
        >
          <a-form-item label="分数">
            <a-input-number
              :min="1"
              style="width: 100%"
              v-decorator="[
                'englishScore',
                {
                  initialValue: detail.englishScore,
                  rules: [{ required: true, message: '请输入！' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :span="8">
          <a-form-item label="计算机水平">
            <a-select
              v-decorator="[
                'computerLevel',

                {
                  initialValue: detail.computerLevel,
                },
              ]"
            >
              <a-select-option
                v-for="item in computerLevelList"
                :key="item.value"
                :value="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8">
          <a-form-item label="职称证书">
            <a-input
              v-decorator="[
                'professionalCert',
                {
                  initialValue: detail.professionalCert,
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :span="8">
          <a-form-item label="注册证书1">
            <a-input
              v-decorator="[
                'registeredCert1',
                {
                  initialValue: detail.registeredCert1,
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="注册证书2">
            <a-input
              v-decorator="[
                'registeredCert2',
                {
                  initialValue: detail.registeredCert2,
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="注册证书3">
            <a-input
              v-decorator="[
                'registeredCert3',
                {
                  initialValue: detail.registeredCert3,
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="其他证书">
            <a-input
              v-decorator="[
                'registeredCertOther',
                {
                  initialValue: detail.registeredCertOther,
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :span="8">
          <a-form-item label="所获奖项1">
            <a-input
              v-decorator="[
                'award1',
                {
                  initialValue: detail.award1,
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="所获奖项2">
            <a-input
              v-decorator="[
                'award2',
                {
                  initialValue: detail.award2,
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <div class="center">
        <a-space>
          <a-button type="primary" html-type="submit" :loading="loading"
            >保存</a-button
          >
          <a-button @click="$close($route.path)">关闭</a-button>
          <!-- <a-button type="danger">删除</a-button> -->
        </a-space>
      </div>
    </a-form>
  </div>
</template>

<script>
import Title from "./title.vue";
import organization from "@/mixins/organization";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { edit } from "@/api/recruit";
import moment from "moment";
import Uploader from "./uploader.vue";
export default {
  mixins: [organization],
  components: {
    Title,
    Uploader,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      header: null, // 上传的证件照 id
      attachment: null, // 上传的简历附件
      loading: false,

      positionVisible: false,
      selectedPosition: [],
    };
  },

  computed: {
    ...mapState("interviewDetail", ["detail"]),

    ...mapGetters("setting", ["findDataDict"]),

    genderList() {
      return this.findDataDict("sex");
    },

    // 政治面貌
    politicalOutlookList() {
      return this.findDataDict("politicalOutlook");
    },

    // 血型
    bloodTypeList() {
      return this.findDataDict("bloodType");
    },

    // 获取信息渠道
    recruitChannelList() {
      return this.findDataDict("recruit_channel");
    },

    booleanList() {
      return this.findDataDict("boolean");
    },
    // 到岗时间
    workTimeList() {
      return this.findDataDict("workTime");
    },

    // 婚姻状态
    marrigeStatusList() {
      return this.findDataDict("marrigeStatus");
    },
    // 学历
    degreeList() {
      return this.findDataDict("degree");
    },
    // 英语水平
    englishLevelList() {
      return this.findDataDict("englishLevel");
    },
    // 计算机水平
    computerLevelList() {
      return this.findDataDict("computerLevel");
    },

    // 岗位列表
    positionTypeList() {
      return this.findDataDict("school_post");
    },
  },
  methods: {
    moment2(value) {
      return moment(value);
    },

    ...mapActions("interviewDetail", ["getDetail"]),
    ...mapMutations("interviewDetail", ["setDetail"]),

    // 上传证件照
    onHeaderChange(fileId) {
      this.header = fileId;
    },
    // 上传简历附件
    onResumeChange(fileId) {
      this.attachment = fileId;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          const department = this.organizationList.find(
            (item) => item.id === values.deptId
          );

          edit({
            ...this.detail,
            ...values,
            birthday: values.birthday
              ? values.birthday.format("YYYY-MM-DD")
              : undefined,
            deptName: department ? department.uniqueName : "", // 部门名称
            deliveryTime: values.deliveryTime
              ? values.deliveryTime.format("YYYY-MM-DD")
              : undefined, // 投递时间

            header: this.header,
            attachment: this.attachment,
          })
            .then(() => {
              this.getDetail();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    setPositionVisible() {
      if (
        this.detail.deliveryChannel === "school_enrollment" &&
        typeof this.detail.post === "string" &&
        this.selectedPosition.length === 0
      ) {
        const list = this.detail.post.split("; ");

        const selectedPosition = [];
        list.forEach((item) => {
          const arr = item && item.split(": ");
          if (arr) {
            const type = arr[0];
            const valueList = arr[1] ? arr[1].split(",") : [];
            valueList.forEach((value) => {
              selectedPosition.push({
                type,
                value,
              });
            });
          }
        });
        console.log("selectedPosition", selectedPosition);

        this.selectedPosition = selectedPosition;
      }
      this.positionVisible = true;
    },
    selectPosition(type, value) {
      const index = this.selectedPosition.findIndex(
        (item) => item.type === type.name && item.value === value.name
      );
      if (index === -1) {
        this.selectedPosition.push({
          type: type.name,
          value: value.name,
        });
      } else {
        this.selectedPosition.splice(index, 1);
      }
    },
    cancelPosition() {
      this.selectedPosition = [];
      this.setDetail({
        ...this.detail,
        post: null,
      });
      this.positionVisible = false;
    },
    savePosition() {
      if (this.selectedPosition.length === 0) {
        this.$message.error("请选择岗位");
        return;
      }

      let arr = [];
      this.selectedPosition
        .map((item) => item.type)
        .filter((item, index, self) => self.indexOf(item) === index)
        .forEach((type) => {
          let list = [];
          this.selectedPosition.forEach((item) => {
            if (item.type === type) {
              list.push(item.value);
            }
          });
          arr.push(`${type}: ${list.join(",")}`);
        });

      this.setDetail({
        ...this.detail,
        post: arr.join("; "),
      });

      this.positionVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.center {
  margin-top: 80px;
}

.position-type-list {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.position-type-title {
  font-weight: bold;
  margin-bottom: 1em;
}
.position-value-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
}
.position-value {
  border: 1px solid #d0d0d0;
  padding: 0.4em 1em;
  cursor: pointer;
}
.selected {
  border-color: #498bff;
  background-color: #498bff;
  color: #fff;
}
</style>
